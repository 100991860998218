<template>
  <div id="inspectionNose">
    <el-dialog
      :title="inspectionNoseFormTitle"
      width="1200px"
      :visible.sync="inspectionNoseDialogVisible"
      :close-on-click-modal="false"
      @close="inspectionNoseDialogClose"
    >
      <el-form
        ref="inspectionNoseFormRef"
        :model="inspectionNoseForm"
        :rules="inspectionNoseFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="inspectionNoseForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="inspectionNoseForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="inspectionNoseForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="取样量" prop="sampleSize">
              <el-input v-model="inspectionNoseForm.sampleSize" placeholder="请输入取样量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker v-model="inspectionNoseForm.inspectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <span>鼻夹</span>
        <el-divider content-position="left">取检测样品，目视检查，通用量具测量</el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：单芯、表面干净，无明显刮痕、压痕、划伤、脏污等现象" label-width="460px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseExteriorResult1">
              <el-radio-group v-model="inspectionNoseForm.noseExteriorResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseExteriorResult2">
              <el-radio-group v-model="inspectionNoseForm.noseExteriorResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseExteriorResult3">
              <el-radio-group v-model="inspectionNoseForm.noseExteriorResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="材质：PP/PE" label-width="160px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseMaterialResult1">
              <el-radio-group v-model="inspectionNoseForm.noseMaterialResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseMaterialResult2">
              <el-radio-group v-model="inspectionNoseForm.noseMaterialResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseMaterialResult3">
              <el-radio-group v-model="inspectionNoseForm.noseMaterialResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="尺寸：3mm±1mm" label-width="200px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseSizeValue1">
              <el-input v-model="inspectionNoseForm.noseSizeValue1" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseSizeValue2">
              <el-input v-model="inspectionNoseForm.noseSizeValue2" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseSizeValue3">
              <el-input v-model="inspectionNoseForm.noseSizeValue3" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseSizeResult1">
              <el-radio-group v-model="inspectionNoseForm.noseSizeResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseSizeResult2">
              <el-radio-group v-model="inspectionNoseForm.noseSizeResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseSizeResult3">
              <el-radio-group v-model="inspectionNoseForm.noseSizeResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="厚度：0.9mm±0.1mm" label-width="200px">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseThicknessValue1">
              <el-input v-model="inspectionNoseForm.noseThicknessValue1" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseThicknessValue2">
              <el-input v-model="inspectionNoseForm.noseThicknessValue2" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseThicknessValue3">
              <el-input v-model="inspectionNoseForm.noseThicknessValue3" placeholder="请输入尺寸" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="noseThicknessResult1">
              <el-radio-group v-model="inspectionNoseForm.noseThicknessResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="noseThicknessResult2">
              <el-radio-group v-model="inspectionNoseForm.noseThicknessResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="noseThicknessResult3">
              <el-radio-group v-model="inspectionNoseForm.noseThicknessResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="noseRemarks">
              <el-input
                v-model="inspectionNoseForm.noseRemarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="检验人" prop="inspector">
              <el-input v-model="inspectionNoseForm.inspector" placeholder="请输入检验人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="检验操作日期" prop="operationDate">
              <el-date-picker v-model="inspectionNoseForm.operationDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="inspectionNoseForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="inspectionNoseForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div slot="footer">
        <el-button @click="inspectionNoseDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inspectionNoseFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inspectionNosePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="sampleSize" label="取样量" />
      <el-table-column prop="inspectionDate" label="检验日期" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column prop="operationDate" label="检验操作日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewDate" label="复核日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inspectionNosePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInspection, deleteInspection, updateInspection, selectInspectionInfo, selectInspectionList } from '@/api/quality/rawMaterialInspection'

export default {
  data () {
    return {
      inspectionNoseDialogVisible: false,
      inspectionNoseFormTitle: '',
      inspectionNoseForm: {
        id: '',
        productName: '',
        batchNo: '',
        spec: '',
        sampleSize: '',
        inspectionDate: '',
        noseExteriorResult1: '',
        noseExteriorResult2: '',
        noseExteriorResult3: '',
        noseMaterialResult1: '',
        noseMaterialResult2: '',
        noseMaterialResult3: '',
        noseSizeResult1: '',
        noseSizeResult2: '',
        noseSizeResult3: '',
        noseThicknessResult1: '',
        noseThicknessResult2: '',
        noseThicknessResult3: '',
        noseSizeValue1: '',
        noseSizeValue2: '',
        noseSizeValue3: '',
        noseThicknessValue1: '',
        noseThicknessValue2: '',
        noseThicknessValue3: '',
        inspector: '',
        operationDate: '',
        reviewer: '',
        reviewDate: '',
        noseRemarks: '',
        type: '1'
      },
      inspectionNoseFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      inspectionNosePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        type: '1',
        dept: ''
      },
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectInspectionList(this.searchForm).then(res => {
      this.inspectionNosePage = res
    })
  },
  methods: {
    inspectionNoseDialogClose () {
      this.$refs.inspectionNoseFormRef.resetFields()
      this.inspectionNoseForm.id = ''
    },
    inspectionNoseFormSubmit () {
      if (this.inspectionNoseFormTitle === '(鼻夹)一次性使用医用口罩原材料检验记录详情') {
        this.inspectionNoseDialogVisible = false
        return
      }
      this.$refs.inspectionNoseFormRef.validate(async valid => {
        if (valid) {
          
          if (this.inspectionNoseFormTitle === '新增(鼻夹)一次性使用医用口罩原材料检验记录') {
            await addInspection(this.inspectionNoseForm)
          } else if (this.inspectionNoseFormTitle === '修改(鼻夹)一次性使用医用口罩原材料检验记录') {
            await updateInspection(this.inspectionNoseForm)
          }
          this.inspectionNosePage = await selectInspectionList(this.searchForm)
          this.inspectionNoseDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inspectionNoseFormTitle = '新增(鼻夹)一次性使用医用口罩原材料检验记录'
      this.inspectionNoseDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInspection(row.id)
        if (this.inspectionNosePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inspectionNosePage = await selectInspectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inspectionNoseFormTitle = '修改(鼻夹)一次性使用医用口罩原材料检验记录'
      this.inspectionNoseDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inspectionNoseFormTitle = '(鼻夹)一次性使用医用口罩原材料检验记录详情'
      this.inspectionNoseDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    selectInspectionInfoById (id) {
      selectInspectionInfo(id).then(res => {
        this.inspectionNoseForm.id = res.id
        this.inspectionNoseForm.productName = res.productName
        this.inspectionNoseForm.batchNo = res.batchNo
        this.inspectionNoseForm.spec = res.spec
        this.inspectionNoseForm.sampleSize = res.sampleSize
        this.inspectionNoseForm.inspectionDate = res.inspectionDate
        this.inspectionNoseForm.noseExteriorResult1 = res.noseExteriorResult1
        this.inspectionNoseForm.noseExteriorResult2 = res.noseExteriorResult2
        this.inspectionNoseForm.noseExteriorResult3 = res.noseExteriorResult3
        this.inspectionNoseForm.noseMaterialResult1 = res.noseMaterialResult1
        this.inspectionNoseForm.noseMaterialResult2 = res.noseMaterialResult2
        this.inspectionNoseForm.noseMaterialResult3 = res.noseMaterialResult3
        this.inspectionNoseForm.noseSizeResult1 = res.noseSizeResult1
        this.inspectionNoseForm.noseSizeResult2 = res.noseSizeResult2
        this.inspectionNoseForm.noseSizeResult3 = res.noseSizeResult3
        this.inspectionNoseForm.noseThicknessResult1 = res.noseThicknessResult1
        this.inspectionNoseForm.noseThicknessResult2 = res.noseThicknessResult2
        this.inspectionNoseForm.noseThicknessResult3 = res.noseThicknessResult3
        this.inspectionNoseForm.noseSizeValue1 = res.noseSizeValue1
        this.inspectionNoseForm.noseSizeValue2 = res.noseSizeValue2
        this.inspectionNoseForm.noseSizeValue3 = res.noseSizeValue3
        this.inspectionNoseForm.noseThicknessValue1 = res.noseThicknessValue1
        this.inspectionNoseForm.noseThicknessValue2 = res.noseThicknessValue2
        this.inspectionNoseForm.noseThicknessValue3 = res.noseThicknessValue3
        this.inspectionNoseForm.noseRemarks = res.noseRemarks
        this.inspectionNoseForm.inspector = res.inspector
        this.inspectionNoseForm.operationDate = res.operationDate
        this.inspectionNoseForm.reviewer = res.reviewer
        this.inspectionNoseForm.reviewDate = res.reviewDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionNosePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionNosePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionNosePage = res
      })
    }
  }
}
</script>

<style>
.vxe-select--panel {
  z-index: 3000 !important;
}
.vxe-input--panel {
  z-index: 3000 !important;
}
</style>
