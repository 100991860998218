import axios from '@/common/axios'
import qs from 'qs'

export function addInspection (data) {
  return axios({
    method: 'post',
    url: '/quality/inspection/add',
    data: qs.stringify(data)
  })
}

export function deleteInspection (id) {
  return axios({
    method: 'delete',
    url: '/quality/inspection/delete/' + id
  })
}

export function updateInspection (data) {
  return axios({
    method: 'put',
    url: '/quality/inspection/update',
    data: qs.stringify(data)
  })
}

export function selectInspectionInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/inspection/info/' + id
  })
}

export function selectInspectionList (query) {
  return axios({
    method: 'get',
    url: '/quality/inspection/list',
    params: query
  })
}
